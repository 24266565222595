@import '../config/custom-variables';

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  box-shadow: $dropdown-box-shadow;

  &--no-padding {
    padding: 0;
  }

  .dropdown-item.active,
  .dropdown-item:active,
  .dropdown-item:hover {
    outline: none;
    text-decoration: none;
  }

  button {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  fa-stack {
    height: auto
  }

  .dropdown-item {
    font-size: 0.875rem;
    border-radius: 0;
    display: flex;
    cursor: pointer;
    text-align: left;
    fa-icon, fa-stack {
      color: var(--gray-600);
      margin-right: 0.5rem;
      width: 1.25rem;
      text-align: center;
      &.danger {
        color: var(--dangerColor);
      }
      &.warning {
        color: var(--warningColor);
      }
      &.success {
        color: var(--successColor);
      }
      &.neutral {
        color: var(--neutralColor);
      }
      &.primary {
        color: var(--primaryColor);
      }
    }
    &--shortcut {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .shortcut {
        margin-left: 1rem;
        color: var(--gray-500);
        font-size: 0.75rem;
      }
    }
    &-primary {
      fa-icon {
        color: var(--primaryColor);
      }
      &:hover,
      &:focus {
        background: var(--primaryColorWashed);
      }
      &:focus {
        box-shadow: 0 0 0 3px var(--primaryColorAlt);
      }
      &.dropdown-item--highlight:hover {
        background: var(--primaryColor);
      }
    }
    &-danger {
      fa-icon {
        color: var(--dangerColor);
      }
      &:hover,
      &:focus {
        background: var(--dangerColorWashed);
      }
      &:focus {
        box-shadow: 0 0 0 3px var(--primaryColorAlt);
      }
      &.dropdown-item--highlight:hover {
        background: var(--dangerColor);
      }
    }
    &-warning {
      fa-icon {
        color: var(--warningColor);
      }
      &:hover,
      &:focus {
        background: var(--warningColorWashed);
      }
      &:focus {
        box-shadow: 0 0 0 3px var(--primaryColorAlt);
      }
      &.dropdown-item--highlight:hover {
        background: var(--warningColor);
      }
    }
    &-success {
      fa-icon {
        color: var(--successColor);
      }
      &:hover,
      &:focus {
        background: var(--successColorWashed);
      }
      &:focus {
        box-shadow: 0 0 0 3px var(--primaryColorAlt);
      }
      &.dropdown-item--highlight:hover {
        background: var(--successColor);
      }
    }
    &--highlight {
      align-items: center;
      &:hover {
        color: var(--white);
        fa-icon {
          color: var(--white);
        }
      }
    }
    &:disabled {
      fa-icon {
        color: var(--neutralColorLight);
      }
    }
  }
}

.dropdown-container--limited {
  max-height: 16rem;
  overflow-y: auto;
}

.dropdown-header {
  @extend .small-title;
  margin-bottom: 0;

  &--has-icon {
    display: flex;

    > fa-icon {
      margin-right: 0.5rem;
    }
  }
}

.hide-default-down-icon {
  .dropdown-toggle::after {
    display: none;
  }
}
