.form-group {
  margin-bottom: $form-group-margin-bottom;
}

.form-group,
.input-group {
  position: relative;
  width: 100%;

  label:not(.form-check-label),
  fa-icon.form-label {
    color: var(--formLabelColor);
    font-size: 0.75rem;
    margin-bottom: 0;
    white-space: nowrap;
    text-overflow: fade;

    &.disabled {
      color: var(--gray-500) !important;
      cursor: not-allowed;
    }

    .typeahead-error {
      margin-top: 0.25rem;
    }
  }
}

.input-group {
  margin: 0 0 0.5rem;
}

label:not(.form-check-label), th, span {
  //for marking the label, table header of span as required without using an actual 'required' attribute
  &.required {
    &:after {
      color: var(--dangerColor);
      content: ' *';
    }
  }

  .required {
    &:after {
      color: var(--dangerColor);
      content: ' *';
    }
  }
}

.form-control {
  &:disabled {
    cursor: not-allowed;
    color: var(--gray-500);
  }
}

.form-control:focus ~ label {
  color: var(--primaryColor);
  font-weight: 500;
}

.has-error-message {
  input {
    @extend .border-danger;
  }
}

.input-error {
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="rgb(209, 47, 48)" d="M255.1 128C269.3 128 280 138.7 280 152V264C280 277.3 269.3 288 255.1 288C242.7 288 231.1 277.3 231.1 264V152C231.1 138.7 242.7 128 255.1 128zM288 352C288 369.7 273.7 384 255.1 384C238.3 384 223.1 369.7 223.1 352C223.1 334.3 238.3 320 255.1 320C273.7 320 288 334.3 288 352zM15.96 218.6L108.5 66.56C121.5 45.1 144.9 32 169.1 32H342C367.1 32 390.5 45.1 403.5 66.56L496 218.6C510 241.6 510 270.4 496 293.4L403.5 445.4C390.5 466.9 367.1 480 342 480H169.1C144.9 480 121.5 466.9 108.5 445.4L15.96 293.4C1.962 270.4 1.962 241.6 15.96 218.6V218.6zM56.96 243.5C52.29 251.2 52.29 260.8 56.96 268.5L149.5 420.5C153.8 427.6 161.6 432 169.1 432H342C350.4 432 358.2 427.6 362.5 420.5L455 268.5C459.7 260.8 459.7 251.2 455 243.5L362.5 91.52C358.2 84.37 350.4 80 342 80H169.1C161.6 80 153.8 84.37 149.5 91.52L56.96 243.5zM403.5 66.56L362.5 91.52L403.5 66.56zM15.96 293.4L56.96 268.5z"/></svg>')
    no-repeat left 1px;
  padding-left: 1.125rem;
  font-size: 0.75rem;
  background-size: 0.875rem;
  color: var(--dangerColor);
  margin-top: 0.25rem;

  &-start-after-end-date {
    margin: -0.75rem 1rem 0 1rem;
  }
}

span.input-error {
  display: block;
}

.checkbox-container,
.radio-container {
  padding-top: 0.5rem;

  label {
    cursor: pointer;
  }
}

.form-check {
  .form-check-label {
    &:hover {
      &:before {
        border-color: var(--primaryColor);
      }
    }
  }

  .form-check-input:disabled,
  .form-check-input[disabled] {
    & ~ .form-check-label {
      cursor: not-allowed;
      color: var(--gray-500);

      &::before {
        border-color: var(--gray-400);
      }
    }

    // make sure disabled inputs have not-allowed cursor, also in bootstrap 5
    pointer-events: auto !important;
    cursor: not-allowed !important;

    background-color: var(--gray-400);
    border-color: var(--gray-500);

    &:checked {
      background-color: var(--gray-500);
    }
  }

  .form-check-input {
    &:hover:not(:disabled):not(.disabled) {
      border-color: var(--primaryColor) !important;
    }
  }
}

// TODO no hover bij toggle switch
.form-switch {
  .form-check-input:hover {
    border-color: var(--gray-500) !important;
  }
}

.append {
  &-container {
    position: relative;

    input {
      width: 100%;
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &.has-prefix {
        padding-left: 1.85rem;
      }

      &.has-suffix {
        padding-right: 1.85rem;
      }
    }
  }

  &-prefix {
    left: 0;
  }

  &-suffix {
    right: 0;
  }

  &-suffix,
  &-prefix {
    text-align: center;
    position: absolute;
    top: 0.2rem;
    font-size: 1.2rem;
    color: var(--gray-500);
    z-index: 9;
    height: 100%;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}

input.form-control.form-control--search {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='rgb(104,121,142)' d='M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z'/%3E%3C/svg%3E");
  background-position: calc(0.5rem) center;
  background-size: 1rem;
  background-repeat: no-repeat;
  padding-left: 2rem;

  &--right {
    background-position: center right calc(0.5rem);
    padding-left: 0.5rem;
    padding-right: 2rem;
  }
}

textarea.mimic-input-element {
  min-height: calc(1.5em + 0.75rem + 2px);
}

form.submitted input.ng-invalid {
  border-color: var(--dangerColor);
}

form.form--submitting {
  pointer-events: none;

  // for footer, controls that are not part of nh-fieldset
  button {
    @extend :disabled;
  }
}

.btn-group.btn-group-toggle {
  fa-icon[icon='check'],
  fa-icon.check {
    display: none;
  }

  &--pills {
    display: flex;
    column-gap: 0.5rem;
    row-gap: 0.25rem;
    flex-wrap: wrap;

    // todo consider setting this a custom variable, e.g. $btn-focus-box-shadow: 0 0 0 0.2rem var(--primaryColorAlt); ?
    .btn-check:focus-visible + .btn,
    form.form--submitting .btn-check:focus-visible + button {
      box-shadow: 0 0 0 3px var(--primaryColorAlt) !important;
    }

    label {
      border-radius: 0.25rem !important;
      font-size: 0.875rem;
      box-shadow: none !important;
      flex-grow: 0;

      * {
        font-weight: 500;
      }

      // active
      &.btn-primary.active {
        background-color: var(--primaryColorWashed) !important;
        border-color: var(--activeToggleBorder) !important;
        color: var(--primaryColor) !important;

        fa-icon[icon='check'],
        fa-icon.check {
          display: inline-block !important;
        }

        &:disabled,
        &.disabled {
          cursor: not-allowed;
          color: var(--gray-500) !important;
          background-color: var(--gray-400) !important;
          border-color: var(--gray-500) !important;
        }
      }

      // not-active
      &.btn-primary:not(.active) {
        background-color: var(--cardBGColor);
        border-color: var(--gray-400) !important;
        color: var(--gray-600);

        &:hover {
          border-color: var(--primaryColorWashed) !important;
          background-color: var(--primaryColorWashed) !important;
          color: var(--primaryColor) !important;
        }

        &:disabled,
        &.disabled {
          cursor: not-allowed;
          color: var(--gray-500) !important;
          background-color: var(--gray-200) !important;
          border-color: var(--gray-400) !important;
        }
      }
    }
  }
}

fieldset:disabled {
  .btn-group.btn-group-toggle {
    &--pills {
      .btn {
        cursor: not-allowed;
      }

      .btn.btn-primary {
        cursor: not-allowed;

        color: var(--gray-500) !important;
        background-color: var(--gray-200) !important;
        border-color: var(--gray-400) !important;

        &.active {
          cursor: not-allowed;
          color: var(--gray-500) !important;
          background-color: var(--gray-400) !important;
          border-color: var(--gray-500) !important;
        }
      }
    }
  }
}

.card-body__container > .nh-row > nh-control > .form-group {
  margin: 0;
}

// experiment whether we can assign form-control like so iso doing it via renderer
.nh-form--experimental {
  nh-control [formControl]:not(NXH-DATE-PICKER, NXH-NUMBER-FIELD),
  nh-control [formControlName]:not(NXH-DATE-PICKER, NXH-NUMBER-FIELD) {
    @extend .form-control;
  }
}

.form-check-mb-deprecated {
  margin-bottom: 0.5rem;
}

.border-danger,
.has-error-message {
  input:not(.ng-valid),
  select,
  textarea,
  .input-group button {
    border-color: var(--dangerColor) !important;
  }
}

.search .search-button {
  background-color: var(--primaryColor);
  border: 1px solid var(--primaryColor);
}
