// General
// These variables overwrite _variables.scss of bootstrap

$font-family-base: Roboto, sans-serif, Lato;
$font-size-base: 0.875rem;
$body-bg: #f3f7fa;
$body-color: #0c1827;
$border-radius: 0.25rem;

$icon-btn-font-size: 1.25rem;

$link-decoration: none;
$link-hover-decoration: none;
$list-group-item-padding-y: 0.5rem;

$zindex-modal: 1100;
$zindex-modal-backdrop: 940;
$modal-content-bg: var(--white);

$cdk-z-index-overlay-container: 1200;

//button
$btn-focus-box-shadow: 0 0 0 0.2rem rgba(var(--primaryColorRgb), 0.25);
$btn-font-size: 0.875rem;
$btn-link-disabled-color: var(--gray-500);
$btn-white-space: nowrap;

// forms: general
$form-group-margin-bottom: 0.5rem;

// forms: inputs
$input-border-color: var(--gray-400);
$input-bg: var(--white);
$input-color: var(--gray-800);
$input-font-size: 0.875rem;
$input-disabled-bg: var(--gray-200);
$input-placeholder-color: var(--gray-500);
$input-focus-bg: var(--white);
$input-focus-border-color: var(--primaryColor);
$input-border-width: 1px;
$input-focus-color: var(--gray-800);
$input-focus-box-shadow: none;

// forms: custom radio buttons
$form-check-label-color: var(--gray-800);
$form-check-input-border: 2px solid var(--gray-500);
$form-check-label-cursor: pointer;
$form-check-input-checked-color: white;
$form-check-input-checked-bg-color: var(--primaryColor);
$form-check-radio-checked-bg-color: var(--primaryColor);
$form-check-input-disabled-opacity: 1;
$form-check-label-disabled-opacity: 1;
$form-check-btn-check-disabled-opacity: 1;
$form-check-input-focus-box-shadow: none;
$form-check-input-active-filter: unset;
$form-check-input-width: 1.05em;
$form-check-margin-bottom: 0;
// workaround to set color of switch's "bolleke" - the actual value is calculated by bootstrap, hence we cannot
// use a css variable.
$form-switch-color: #a6b2c4;
$form-switch-focus-color: #a6b2c4;

// styling of tabs - see also _moapr.scss
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-border-color: transparent;

// tooltip
$tooltip-max-width: 300px;
$enable-shadows: false;

//alerts
$alert-padding-y: 1rem;
$alert-bg-level: -11;
$alert-border-level: 0;
$alert-color-level: 0;
$alert-border-width: 0 0 0 8px;

//cards
$card-spacer-y: 1.5rem;
$card-spacer-x: 1.5rem;
$card-cap-padding-y: 1.5rem;

$card-border-width: 0;
$card-border-radius: 0.25rem;
$card-bg: var(--cardBGColor);

$card-cap-color: var(--gray-700);
$card-color: var(--gray-800);

//badge
$badge-font-size: 0.75rem;
$badge-font-weight: 400;
$badge-pill-border-radius: 4px;

//dropdown
$dropdown-bg: var(--white);
$dropdown-border-width: 0;
$dropdown-box-shadow: 0 2px 20px 0 rgba(144, 150, 170, 0.2);
$dropdown-link-color: var(--bodyTextColor);
$dropdown-link-hover-color: var(--bodyTextColor);
$dropdown-link-hover-bg: var(--primaryColorWashed);
$dropdown-header-padding: 0.5rem 1rem;

//list-group
$list-group-color: var(--gray-700);
$list-group-border-color: var(--gray-200);

$list-group-item-padding-y: 1rem;
$list-group-item-padding-x: 1rem;

$list-group-active-color: var(--primaryColor);
$list-group-active-bg: var(--gray-200);

//tables
$table-color: var(--gray-800);
$table-bg: transparent !default;

//tooltips
$zindex-tooltip: 1101;
$tooltip-font-size: 0.75rem;
$tooltip-color: var(--gray-100);
$tooltip-bg: var(--gray-800);
$tooltip-border-radius: 1.25rem;
$tooltip-padding-y: 0.5rem;
$tooltip-padding-x: 1rem;
