:is(
    main,
    header,
    div,
    label,
    span,
    button,
    a,
    li,
    section,
    aside,
    h1,
    h2,
    h3,
    h4,
    h5,
    td,
    th,
    p,
    .first-letter-capitalized
  ):not(.no-capitalization, .form-group) {
  &::first-letter {
    text-transform: capitalize;
  }
}

/* width */
::-webkit-scrollbar {
  width: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--gray-400);
  border-radius: 0.25rem;
}

html,
body {
  overflow-x: hidden;
  height: 100%;
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

input,
textarea {
  font-size: 0.875rem;

  &::placeholder {
    font-size: 0.75rem;
    color: var(--gray-500);
  }
}

textarea::placeholder {
  line-height: 1.4rem;
}

.input-group-text {
  font-size: 0.75rem;
}

a {
  color: var(--primaryColor);

  &:hover {
    color: var(--primaryColorDark);
  }

  &:focus-visible {
    outline: none;
  }
}

h1 {
  color: var(--gray-800);
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 400;
  margin-bottom: 0;
}

fa-icon {
  &.primary {
    color: var(--primaryColor);
  }

  &.success {
    color: var(--successColor);
  }

  &.warning {
    color: var(--warningColor);
  }

  &.danger {
    color: var(--dangerColor);
  }
}

.page-container {
  top: 4.5rem;
  height: calc(100% - 4.5rem);
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  width: 100%;
  @media screen and (max-width: 1280px) {
    top: 5rem;
  }
}

.nh-text-smaller,
.ng-text-smaller * {
  font-size: 90%;
}

.nh-cursor-as-pointer:hover,
.nh-cursor-as-pointer:hover * {
  cursor: pointer !important;
}

.list-item__dropdown .dropdown-item {
  &:hover,
  &:active {
    background: var(--primaryColorWashed);
    color: var(--bodyTextColor);
  }
}

.row {
  position: relative;
}

.user-select-none {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
}

.separator {
  padding: 0 0.25rem;
  color: var(--gray-400);
}

ngb-modal-backdrop {
  opacity: 0;
}

.nxh-deprecated-checkbox-mb {
  margin-bottom: 0.75rem;
}

@mixin disabled {
  cursor: not-allowed;
  color: var(--gray-500) !important;
  background-color: var(--gray-200) !important;
  border-color: var(--gray-400) !important;
}

.nxh-overflow-y-hidden {
  overflow-y: hidden;
}
