$toastTypes: (
  'success': 'success',
  'error': 'danger',
  'warning': 'warning',
  'info': 'primary',
);

/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 80px;
  right: 16px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: 500;
  &:first-child:nth-last-child(1) {
    font-weight: 400;
  }
}

.toast-message {
  word-wrap: break-word;
  font-weight: 400;
}

.toast-message a,
.toast-message label {
  color: var(--white);
}

.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}

.toast-close-button {
  position: absolute;
  right: 1rem;
  top: 1rem;
  line-height: 1rem;
  font-size: 1.5rem;
  color: var(--white);
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: var(--white);
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
  font-size: 14px;
  line-height: 1.43;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 0.375rem;
  padding: 15px 3rem;
  width: 25rem;
  border-radius: 0.5rem;
  background-position: 1rem 1rem;
  background-repeat: no-repeat;
  background-size: 1rem;
  color: var(--white);

  &.toast-info {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='rgb(255,255,255)' stroke-width='16' stroke='rgb(255,255,255)' d='M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-36 344h12V232h-12c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h48c6.627 0 12 5.373 12 12v140h12c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-72c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12zm36-240c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z'/%3E%3C/svg%3E");
  }

  &.toast-error {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="rgb(255,255,255)" d="M255.1 128C269.3 128 280 138.7 280 152V264C280 277.3 269.3 288 255.1 288C242.7 288 231.1 277.3 231.1 264V152C231.1 138.7 242.7 128 255.1 128zM288 352C288 369.7 273.7 384 255.1 384C238.3 384 223.1 369.7 223.1 352C223.1 334.3 238.3 320 255.1 320C273.7 320 288 334.3 288 352zM15.96 218.6L108.5 66.56C121.5 45.1 144.9 32 169.1 32H342C367.1 32 390.5 45.1 403.5 66.56L496 218.6C510 241.6 510 270.4 496 293.4L403.5 445.4C390.5 466.9 367.1 480 342 480H169.1C144.9 480 121.5 466.9 108.5 445.4L15.96 293.4C1.962 270.4 1.962 241.6 15.96 218.6V218.6zM56.96 243.5C52.29 251.2 52.29 260.8 56.96 268.5L149.5 420.5C153.8 427.6 161.6 432 169.1 432H342C350.4 432 358.2 427.6 362.5 420.5L455 268.5C459.7 260.8 459.7 251.2 455 243.5L362.5 91.52C358.2 84.37 350.4 80 342 80H169.1C161.6 80 153.8 84.37 149.5 91.52L56.96 243.5zM403.5 66.56L362.5 91.52L403.5 66.56zM15.96 293.4L56.96 268.5z"/></svg>');
  }

  &.toast-success {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' stroke-width='16' stroke='rgb(255,255,255)' d='M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z'/%3E%3C/svg%3E");
  }

  &.toast-warning {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="rgb(33,37,41)" d="M506.3 417l-213.3-364C284.8 39 270.4 32 256 32C241.6 32 227.2 39 218.1 53l-213.2 364C-10.59 444.9 9.851 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM52.58 432L255.1 84.8L459.4 432H52.58zM256 337.1c-17.36 0-31.44 14.08-31.44 31.44c0 17.36 14.11 31.44 31.48 31.44s31.4-14.08 31.4-31.44C287.4 351.2 273.4 337.1 256 337.1zM232 184v96C232 293.3 242.8 304 256 304s24-10.75 24-24v-96C280 170.8 269.3 160 256 160S232 170.8 232 184z"/></svg>');
    color: #212529;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 2rem,
      rgba(255, 255, 255, 0.1) 2rem,
      rgba(255, 255, 255, 0.1) 2.25rem
    );
  }
  > div:last-child:not(.toast-message) {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 0.25rem;
      width: 100%;
      z-index: 0;
      background-color: var(--white);
    }
  }
}

.toast-container .ngx-toastr:hover {
  opacity: 1;
  cursor: pointer;
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 18.75rem;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

@each $css, $type in $toastTypes {
  .toast-#{$css} {
    background-color: var(--#{$type}Color);
    background-position: center top;
    .toast-progress {
      background-color: var(--#{$type}Color);
    }
  }
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0.25rem;
  z-index: 1;
  opacity: 0.3;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 0.5rem 0.5rem 0.5rem 3.125rem;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 0.5rem 0.5rem 0.5rem 3.125rem;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 1rem 1rem 1rem 3.125rem;
    width: 25em;
  }
}
