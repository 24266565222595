$types: primary, success, warning, danger, neutral;

$small-button-font-size: 0.75rem;

[nxh-button].no-capitalization {
  span::first-letter {
    text-transform: unset;
  }
}

button[nxh-button] > * {
  pointer-events: none;
}

.btn {
  text-align: center;
  @media screen and (max-width: 1280px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  &:disabled,
  &.disabled {
    color: var(--gray-500) !important;
    background-color: var(--gray-400) !important;
    border-color: var(--gray-400) !important;
    cursor: not-allowed !important;
    pointer-events: auto !important;
  }

  > * {
    display: inline-block;
    vertical-align: middle;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  &--small {
    font-size: $small-button-font-size;
  }

  &--no-padding {
    padding: 0 !important;
  }

  @each $type in $types {
    &-subtle-#{$type} {
      color: var(--#{$type}Color);
      border: thin solid transparent;
      display: flex;

      &:not(:disabled):not(.disabled) {
        &:hover {
          color: var(--#{$type}Color);
          border: thin solid var(--#{$type}ColorWashed);
        }

        &:focus,
        &:focus-visible {
          color: var(--#{$type}Color);
          box-shadow: 0 0 0 3px var(--primaryColorAlt);
          border: thin solid transparent;
        }

        &:active,
        &.active {
          border: thin solid var(--#{$type}ColorWashed);
          color: var(--#{$type}Color);
          background-color: var(--#{$type}ColorWashed);
        }
      }
    }
  }

  &.btn--subtle-small {
    line-height: 0.875rem;
    padding: 0 !important;
  }

  fa-stack {
    height: auto !important;
    width: 1rem;
    &--small {
      width: $small-button-font-size;
    }
  }
}

@each $type in $types {
  .btn-#{$type},
  .btn-subtle-#{$type},
  .btn-outline-#{$type} {
    font-weight: 500;

    &::first-letter {
      text-transform: capitalize;
    }

    &:disabled {
      color: var(--gray-500) !important;
      background-color: var(--gray-400) !important;
      border-color: var(--gray-400) !important;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled):active:focus-visible,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus-visible {
      box-shadow: none !important;
    }
  }

  .btn-#{$type} {
    background-color: var(--#{$type}Color);
    border-color: var(--#{$type}Color);

    &:not(:disabled):not(.disabled) {
      &:hover {
        background-color: var(--#{$type}ColorLight);
        border-color: var(--#{$type}ColorLight);
      }

      &:focus,
      &:focus-visible {
        background-color: var(--#{$type}Color);
        border-color: var(--#{$type}Color);
        box-shadow: 0 0 0 3px var(--primaryColorAlt);
      }

      &:active,
      &.active {
        background-color: var(--#{$type}ColorDark);
        border-color: var(--#{$type}ColorDark);
      }
    }
  }

  .btn-outline-#{$type} {
    color: var(--#{$type}Color);
    background-color: var(--cardBGColor);
    border-color: var(--#{$type}Color);

    &:not(:disabled):not(.disabled) {
      &:hover {
        color: var(--#{$type}ColorLight);
        background-color: var(--#{$type}ColorWashed);
        border-color: var(--#{$type}ColorLight);
      }

      &:focus,
      &:focus-visible {
        color: var(--#{$type}Color);
        border-color: var(--#{$type}Color);
        background-color: var(--cardBGColor);
        box-shadow: 0 0 0 3px var(--primaryColorAlt);
      }

      &:active,
      &.active {
        border-color: var(--#{$type}ColorDark);
        color: var(--#{$type}ColorDark);
        background-color: var(--#{$type}ColorWashed);
      }
    }
  }
}

.btn-link-small {
  padding: 0;
  line-height: 1rem;
  vertical-align: inherit;
  font-size: inherit;
  font-weight: inherit;

  cursor: pointer;
  color: var(--primaryColor);
  border: 0;

  > * {
    vertical-align: inherit;

    &:not(:last-child) {
      margin-right: 0.25rem;
    }
  }

  &:not(:disabled):not(.disabled) {
    &:focus,
    &:focus-visible {
      color: var(--primaryColorLight) !important;
      box-shadow: 0 0 0 3px var(--primaryColorAlt) !important;
    }

    &:hover {
      color: var(--primaryColorLight) !important;
      text-decoration: underline;

      div,
      span {
        text-decoration: underline;
      }
    }

    &:active {
      color: var(--primaryColorDark) !important;
    }
  }

  &:disabled,
  &.disabled {
    color: var(--disabledColor) !important;
    border: none !important;
    background-color: transparent !important;
    cursor: not-allowed;
  }
}

.btn-icon,
.btn-outline-icon {
  height: 2rem;
  width: 2rem;
  padding: 0;

  > fa-icon {
    svg {
      width: 1rem;
    }
  }

  > fa-stack {
    width: 1rem;

    .fa-stack-2x {
      width: 1rem;
    }

    .fa-stack-1x {
      width: 0.5rem;
    }
  }

  &:disabled,
  &.disabled {
    background-color: inherit !important;
    border: thin solid transparent !important;

    fa-icon {
      pointer-events: none;
    }
  }

  &-small {
    height: 1rem;
    width: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.btn-icon {
  //default colors, if no .btn-icon-${type} is present.
  color: var(--primaryColor);

  &:not(:disabled):not(.disabled) {
    &:hover {
      color: var(--primaryColorLight);
      border-color: var(--primaryColorWashed);
      background-color: var(--primaryColorWashed);
    }

    &:focus,
    &:focus-visible {
      color: var(--primaryColorLight);
      border-color: var(--primaryColorAlt);
      box-shadow: 0 0 0 3px var(--primaryColorAlt);
    }

    &:active {
      color: var(--primaryColorDark);
      border-color: var(--primaryColorWashed);
      background-color: var(--primaryColorWashed);
    }
  }

  // end of default colors

  @each $type in $types {
    &-#{$type} {
      color: var(--#{$type}Color);

      &:not(:disabled):not(.disabled) {
        &:hover {
          color: var(--#{$type}ColorLight);
          background-color: var(--#{$type}ColorWashed);
          border-color: var(--#{$type}ColorWashed);
        }

        &:focus,
        &:focus-visible {
          color: var(--#{$type}ColorLight);
          border-color: var(--primaryColorAlt);
          box-shadow: 0 0 0 3px var(--primaryColorAlt);
        }

        &:active,
        &.active {
          border-color: var(--#{$type}ColorWashed);
          color: var(--#{$type}ColorDark);
          background-color: var(--#{$type}ColorWashed);
        }
      }
    }
  }
}

.btn-outline-icon {
  //default colors, if not using nxh-button
  color: var(--primaryColor);
  border-color: var(--primaryColor);
  background-color: var(--cardBGColor);

  &:not(:disabled):not(.disabled) {
    &:hover {
      color: var(--primaryColorLight);
      border-color: var(--primaryColorLight);
      background-color: var(--primaryColorWashed);
    }

    &:focus,
    &:focus-visible {
      color: var(--primaryColor);
      background-color: var(--cardBGColor);
      border-color: var(--primaryColor);
      box-shadow: 0 0 0 3px var(--primaryColorAlt);
    }

    &:active {
      color: var(--primaryColorDark);
      border-color: var(--primaryColorDark);
      background-color: var(--primaryColorWashed);
    }
  }

  // end of default colors

  @each $type in $types {
    &-#{$type} {
      color: var(--#{$type}Color);
      border-color: var(--#{$type}Color);
      background-color: var(--cardBGColor);

      &:not(:disabled):not(.disabled) {
        &:hover {
          color: var(--#{$type}ColorLight);
          border-color: var(--#{$type}ColorLight);
          background-color: var(--#{$type}ColorWashed);
        }

        &:focus,
        &:focus-visible {
          color: var(--#{$type}Color);
          background-color: var(--cardBGColor);
          border-color: var(--#{$type}Color);
          box-shadow: 0 0 0 3px var(--primaryColorAlt);
        }

        &:active {
          color: var(--#{$type}ColorDark);
          border-color: var(--#{$type}ColorDark);
          background-color: var(--#{$type}ColorWashed);
        }
      }
    }
  }
}

.btn-action-panel {
  // some rules are !important because (currently?) the form.form--submitting button rule meddles with this
  font-size: $small-button-font-size !important;
  text-align: left !important;
  color: var(--gray-800);
  background-color: var(--gray-200);
  border-color: var(--gray-200);

  fa-icon {
    color: var(--gray-600);
  }

  &:focus {
    border-color: var(--gray-200);
    box-shadow: 0 0 0 3px var(--primaryColorAlt);
  }

  &:hover {
    background-color: var(--gray-300);
    border-color: var(--gray-300);

    fa-icon {
      color: var(--primaryColorLight);
    }
  }

  &:active {
    background-color: var(--gray-400);
    border-color: var(--gray-400);

    fa-icon {
      color: var(--primaryColor);
    }

    &:focus {
      box-shadow: none;
    }
  }

  &:disabled {
    color: var(--color-600);
    background-color: var(--gray-100);
    border-color: var(--gray-100);

    fa-icon {
      color: var(--gray-500);
    }
  }
}

.btn-group {
  .btn-primary {
    background-color: var(--cardBGColor);
    border: 1px solid var(--gray-400);
    color: var(--gray-600);
    margin: 0;

    &:not(:disabled):not(.disabled) {
      &:focus,
      &:active,
      &:hover {
        border: 1px solid var(--primaryColor);
        background-color: var(--primaryColor);
        color: #fff;
      }

      &.active {
        border: 1px solid var(--primaryColorDark);
        background-color: var(--primaryColorDark);
        color: #fff;
      }
    }
  }
}

// Er zijn nog plekken in de applicatie waar dit moet.. bv ngbDropdownItem zonder de classes dropdown-item-danger
button:not(:disabled) fa-icon[icon='trash-alt'] {
  color: var(--dangerColor);
}

// btn-check is a bootstrap class that has nothing to do with real buttons and above scss.
.btn-check {
  &:disabled {
    background-color: var(--gray-100);
    border-color: var(--gray-500);

    + label.active {
      background-color: var(--gray-400) !important;
    }
  }

  &:hover:not(:disabled):not(.disabled) {
    border-color: var(--primaryColor);
  }

  &[disabled],
  &:disabled {
    + label {
      background-color: var(--gray-100) !important;
      color: var(--gray-500) !important;
    }
  }
}
