@use 'sass:math';

$cols: 12;
$gutter: 1rem;

$break-small: 760px;
$break-medium: 900px;
$break-wide: 1200px;

*,
*:before,
*:after {
  box-sizing: border-box;
}

.nh-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$gutter;
  width: calc(100% + #{$gutter});

  > * {
    margin: 0;
  }

  &--center {
    align-items: center;
  }

  &--bottom {
    align-items: flex-end;
  }

  &--baseline {
    align-items: baseline;
  }
}

.row-wrap {
  flex-wrap: wrap;
}

.nh-col {
  flex: 1;
  padding: 0 $gutter 0 0;
}

.nh-col--nopad {
  padding: 0 !important;
}

.nh-col-s {
  flex: 0;
}

$breakpoint-wide: 1439px;
$breakpoint-wide-so: calc(1439px + 244px);
$breakpoint-wide-mo: calc(1439px + 480px);
$breakpoint-wide-so-mo: calc(1439px + 724px);
$breakpoint-medium: 1100px;
$breakpoint-medium-so: calc(1100px + 244px);
$breakpoint-medium-mo: calc(1100px + 480px);
$breakpoint-medium-so-mo: calc(1100px + 724px);
$breakpoint-small: 900px;
$breakpoint-small-so: calc(900px + 244px);
$breakpoint-small-mo: calc(900px + 480px);
$breakpoint-small-so-mo: calc(900px + 724px);

@for $i from 1 through $cols {
  .nh-col-#{$i} {
    flex: 0 1 math.div($i, $cols) * 100%;
    padding: 0 $gutter 0 0;
    max-width: math.div($i, $cols) * 100%;
    height: auto;
  }

  .nh-col-#{$i}--margin {
    padding: 0 0 0 0;
    margin: 0 $gutter 0 0;
  }
}

@media screen and (max-width: $breakpoint-wide) {
  @for $i from 1 through $cols {
    .nh-col-md-#{$i} {
      flex: 0 1 math.div($i, $cols) * 100%;
      padding: 0 $gutter 0 0;
      max-width: math.div($i, $cols) * 100%;
    }
  }
}

@media screen and (max-width: $breakpoint-wide-so) {
  .sidemenu-open {
    @for $i from 1 through $cols {
      .nh-col-md-#{$i} {
        flex: 0 1 math.div($i, $cols) * 100%;
        padding: 0 $gutter 0 0;
        max-width: math.div($i, $cols) * 100%;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-wide-mo) {
  .mud-open {
    @for $i from 1 through $cols {
      .nh-col-md-#{$i} {
        flex: 0 1 math.div($i, $cols) * 100%;
        padding: 0 $gutter 0 0;
        max-width: math.div($i, $cols) * 100%;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-wide-so-mo) {
  .mud-open.sidemenu-open {
    @for $i from 1 through $cols {
      .nh-col-md-#{$i} {
        flex: 0 1 math.div($i, $cols) * 100%;
        padding: 0 $gutter 0 0;
        max-width: math.div($i, $cols) * 100%;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-medium) {
  @for $i from 1 through $cols {
    .nh-col-sm-#{$i} {
      flex: 0 1 math.div($i, $cols) * 100%;
      padding: 0 $gutter 0 0;
      max-width: math.div($i, $cols) * 100%;
    }
  }
}

@media screen and (max-width: $breakpoint-medium-so) {
  .sidemenu-open {
    @for $i from 1 through $cols {
      .nh-col-sm-#{$i} {
        flex: 0 1 math.div($i, $cols) * 100%;
        padding: 0 $gutter 0 0;
        max-width: math.div($i, $cols) * 100%;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-medium-mo) {
  .mud-open {
    @for $i from 1 through $cols {
      .nh-col-sm-#{$i} {
        flex: 0 1 math.div($i, $cols) * 100%;
        padding: 0 $gutter 0 0;
        max-width: math.div($i, $cols) * 100%;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-medium-so-mo) {
  .mud-open.sidemenu-open {
    @for $i from 1 through $cols {
      .nh-col-sm-#{$i} {
        flex: 0 1 math.div($i, $cols) * 100%;
        padding: 0 $gutter 0 0;
        max-width: math.div($i, $cols) * 100%;
      }
    }
  }
}
