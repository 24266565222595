.group-table {
  background: var(--white);
  border-radius: var(--cardBorderRadius);
  // TODO FEG-1240 stijn: deze mogen denk ik weg, lijkt op een workaround voor de row class die margin toevoegt
  // padding-right: 1rem;
  // padding-left: 1rem;
  table-layout: fixed;
  width: 100%;

  &__header {
    display: flex;
    border-bottom: 2px solid var(--gray-300);
    color: var(--gray-600);
    font-weight: 500;
    align-items: flex-start;
    flex-wrap: nowrap;

    &__inner {
      width: calc(100% - 13rem);
      display: flex;
      position: relative;
      @media screen and (max-width: 1280px) {
        width: calc(100% - 10rem);
      }
    }

    &__dates {
      width: calc(100% - 6rem);
      margin-left: 3rem;
      display: flex;
    }
  }

  &__description {
    padding: 1rem 0.75rem;
    width: 13rem;
    line-height: 1;
    background: var(--gray-100);
    word-break: break-word;
    border-right: 1px solid var(--gray-300);
    @media screen and (max-width: 1280px) {
      width: 10rem;
    }

    &__unit {
      float: right;
    }
  }

  &__parameter {
    display: flex;
    border-bottom: 1px solid var(--gray-400);
    flex-wrap: nowrap;

    .group-table__description {
      padding: 0.5rem 0.75rem;

      &__label {
        margin-bottom: 0.5rem;
        line-height: 1.2;

        button {
          padding: 0;
          text-align: left;
        }
      }

      &__latest {
        font-size: 0.75rem;

        &--info {
          color: var(--gray-600);
          display: flex;
          justify-content: space-between;
          font-size: 0.7rem;
          line-height: 1.125rem;

          fa-icon {
            margin-right: 0.25rem;
          }
        }

        &__value {
          padding-left: 0.125rem;
          font-weight: 500;

          &__red {
            color: var(--dangerColor);
          }
        }
      }
    }

    &__inner {
      width: calc(100% - 13rem);
      display: flex;
      @media screen and (max-width: 1280px) {
        width: calc(100% - 10rem);
      }
    }
  }

  &__paging {
    width: 3rem;
    padding: 0 !important;
    text-align: center;
    position: absolute;
    top: 0.35rem;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }

    .btn:disabled {
      opacity: 0.35;
      cursor: not-allowed;
    }
  }

  &__date {
    padding: 1rem;
    width: calc(100% / 7);
    word-break: break-word;
    line-height: 1;
    text-align: left;

    &--last {
      background: var(--warningColorWashed);
    }
  }

  &__datas {
    width: calc(100% - 6rem);
    margin-left: 3rem;
    display: flex;
  }

  &__data {
    padding: 0.5rem 1rem;
    width: calc(100% / 7);
    align-items: flex-start;
    display: flex;
    justify-content: center;
    flex-direction: column;

    > div {
      padding: 0.25rem 0;
    }

    &__time {
      font-size: 0.625rem;
      color: var(--gray-600);
      margin-right: 0.5rem;
      line-height: 1;
    }

    &__attribute {
      margin-right: 0;
      line-height: 1;
    }

    &__extra {
      margin-left: 0.5rem;
      line-height: 1;
      color: var(--gray-600);

      &:hover {
        color: var(--primaryColor);
      }
    }

    &__tooltip-title {
      font-size: 0.875rem;
    }

    &__tooltip-body {
      font-size: 0.8125rem;
    }

    &--last {
      background: var(--warningColorWashed);
    }

    &__value {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      position: relative;
      justify-content: center;
      padding: 0;

      &--top {
        justify-content: flex-start;
      }

      &--bottom {
        justify-content: flex-end;
      }

      &:hover {
        button {
          display: inline-block;
        }
      }

      fa-icon {
        margin-left: 0.25rem;
      }

      &__red {
        color: var(--dangerColor);
      }

      button {
        position: absolute;
        line-height: 1;
        color: var(--primaryColor);
        right: 0;
        padding: 0;
        display: none;
      }
    }
  }

  &--5cols {
    .group-table {
      &__data,
      &__date {
        width: calc(100% / 5);
      }
    }
  }
}
